/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'

const portfolios = [
    {
        id: 1,
        image: IMG1,
        title: 'Title1',
        github: 'https://github.com',
        demo: 'https://github.com',
    },
    {
        id: 2,
        image: IMG2,
        title: 'Title2',
        github: 'https://github.com',
        demo: 'https://github.com',
    },
    {
        id: 3,
        image: IMG3,
        title: 'Title3',
        github: 'https://github.com',
        demo: 'https://github.com',
    },
]

const Portfolio = () => {
    return (
        <section id="portfolio">
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>

            <div className="container portfolio__container">
                {portfolios.map(({ id, image, title, github, demo }) => (
                    <article key={id} className="portfolio__item">
                        <div className="portfolio__item-image">
                            <img src={image} alt={title} />
                        </div>
                        <h3>{title}</h3>
                        <div className="portfolio__item-cta">
                            <a href={github} className="btn">
                                Github
                            </a>
                            <a
                                href={demo}
                                className="btn btn-primary"
                                target="_blank">
                                Live Demo
                            </a>
                        </div>
                    </article>
                ))}
            </div>
        </section>
    )
}

export default Portfolio
