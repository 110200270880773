import React, { useRef } from 'react'
import emailjs from 'emailjs-com'
import './contact.css'
import { MdOutlineMail } from 'react-icons/md'
import { RiMessengerLine } from 'react-icons/ri'
import { RiWhatsappLine } from 'react-icons/ri'

const Contact = () => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs
            .sendForm(
                'service_z8a3fn1',
                'template_cxjsucc',
                form.current,
                'CJlao3jsAnrhDTYzZ'
            )
            .then(
                (result) => {
                    console.log(result.text)
                },
                (error) => {
                    console.log(error.text)
                }
            )

        e.target.reset()
    }

    return (
        <section id="contact">
            <h5>Get In Touch</h5>
            <h2>Contact Me</h2>

            <div className="container contact__container">
                <div className="contact__options">
                    <article className="contact__option">
                        <MdOutlineMail className="contact__option-icon" />
                        <h4>Email</h4>
                        <h5>nqtoai2101@gmail.com</h5>
                        <a
                            href="mailto:nqtoai2181@gmail.com"
                            target="_blank"
                            rel="noreferrer">
                            Send a message
                        </a>
                    </article>
                    <article className="contact__option">
                        <RiMessengerLine className="contact__option-icon" />
                        <h4>Messenger</h4>
                        <h5>Ngô Toại</h5>
                        <a
                            href="http://m.me/Toaingo00"
                            target="_blank"
                            rel="noreferrer">
                            Send a message
                        </a>
                    </article>
                    <article className="contact__option">
                        <RiWhatsappLine className="contact__option-icon" />
                        <h4>WhatsApp</h4>
                        <h5>+84 (0) 986 744 529</h5>
                        <a
                            href="https://api.whatsapp.com/send?phone=986744529"
                            target="_blank"
                            rel="noreferrer">
                            Send a message
                        </a>
                    </article>
                </div>
                {/* END OF CONTACT OPTIONS */}

                <form ref={form} onSubmit={sendEmail}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Your Full Name"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        required
                    />
                    <textarea
                        name="message"
                        rows="7"
                        placeholder="Your Message"
                        required></textarea>
                    <button type="submit" className="btn btn-primary">
                        Send Message
                    </button>
                </form>
            </div>
        </section>
    )
}

export default Contact
